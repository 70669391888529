import React from 'react';
import io from 'socket.io-client'



let appName =  "local"//process.env.REACT_APP_SOCKET_NAME;


switch(process.env.REACT_APP_BASE_URL) {
    case "https://epfdlw-api.gtoken.in/":
        appName = "epfdlw";
        break;
    case "https://epfvdro-api.gtoken.in/":
        appName = "epfvdro";
        break;
    case "https://epfldjal-api.gtoken.in/":
        appName = "epfldjal";
        break;
    case "https://epfpbchd-api.gtoken.in/":
        appName = "epfpbchd";
        break;
    case "https://epfrjudr-api.gtoken.in/":
        appName = "epfrjudr";
        break;
    case "https://nnk-api.gtoken.in/":
        appName = "nnk";
        break;
    case "https://nnynr-api.gtoken.in/":
        appName = "nnynr";
        break;
    case "https://nnpkl-api.gtoken.in/":
        appName = "nnpkl";
        break;
    case "https://nnpnp-api.gtoken.in/":
        appName = "nnpnp";
        break;
    case "https://nnumb-api.gtoken.in/":
        appName = "nnumb";
        break;
    case "https://nnfbd-api.gtoken.in/":
        appName = "nnfbd";
        break;
    case "https://rok-api.gtoken.in/":
        appName = "rok";
        break;
    case "https://hc-api.gtoken.in/":
        appName = "hc";
        break;
    case "https://hcbaran-api.gtoken.in/":
        appName = "hcbaran";
        break;
    case "https://demo-api.gtoken.in/":
        appName = "demo";
        break;
    case "http://localhost:8000/":
        appName = "local";
        break;
}


//appName = "local";


export const socket = io.connect("https://tms-notification.gtoken.in?app="+appName);
export const SocketContext = React.createContext();